import { createFilterParams } from "./filterParams.js";
export function createUrl(page, rowsPerPage, dataListFilter, dataListSort) {
  const pageSize = rowsPerPage ? `&pageSize=${rowsPerPage}` : "";

  let sortParams = "";
  if (dataListSort && dataListSort.length > 0) {
    dataListSort.forEach((elem, index) => {
      sortParams += `&order.${elem.name}.isDesc=${elem.isDesc}&order.${elem.name
      }.order=${index + 1}`;
    });
  }

  let filterParams = createFilterParams(dataListFilter);
  if (!filterParams) {
    filterParams = "&filter=null";
  }


  return `${window.location.pathname}?page=${page}${pageSize}${filterParams}${sortParams}`;
}