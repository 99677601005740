<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span
        style="cursor: pointer"
        v-on="on"
      >{{ text | cropText(maxLength) }}</span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["text", "maxLength"],
};
</script>