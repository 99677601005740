export function createFilterParams(dataListFilter) {
  let filterParams = "";
  for (const prop in dataListFilter) {
    if (
      dataListFilter[prop].type === "decimal" ||
              dataListFilter[prop].type === "int" ||
              dataListFilter[prop].type === "date"
    ) {
      if (dataListFilter[prop].valueFrom) {
        filterParams += `&filter.${prop}From=${dataListFilter[prop].valueFrom}`;
      }
      if (dataListFilter[prop].valueTo) {
        filterParams += `&filter.${prop}To=${dataListFilter[prop].valueTo}`;
      }
    } else if (
      dataListFilter[prop].value &&
              dataListFilter[prop].type === "bool"
    ) {
      filterParams += `&filter.${prop}=${dataListFilter[prop].value.booleanValue}`;
    } else if (
      dataListFilter[prop].type === "address" &&
              dataListFilter[prop].value !== null
    ) {
      dataListFilter[prop].value.forEach(v => {
        filterParams += `&filter.${prop}=${v.id}`;
      });
    } else if (
      dataListFilter[prop].type === "reference" &&
              dataListFilter[prop].value !== null
    ) {
      dataListFilter[prop].value.forEach(v => {
        filterParams += `&filter.${prop}Ids=${v.id}`;
      });
    } else if (
      dataListFilter[prop].type === "enum" &&
              dataListFilter[prop].value !== null
    ) {
      dataListFilter[prop].value.forEach(v => {
        filterParams += `&filter.${prop}=${v.id}`;
      });
    } else {
      if (dataListFilter[prop].value) {
        const paramValue = dataListFilter[prop].value;
        filterParams += `&filter.${prop}=${paramValue}`;
      }
    }
  }
  if (!filterParams) {
    filterParams = "&filter=null";
  }
  return filterParams;
}