var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-1 mb-1",attrs:{"tile":""}},[_c('v-card-title',{staticClass:"pa-2"},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-3 mx-2",attrs:{"slot":"activator","small":"","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}},slot:"activator"},on),[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v(" mdi-arrow-left ")])],1)]}}])},[_c('span',[_vm._v("Назад")])]),_c('h1',{staticClass:"title my-3 mx-2"},[_vm._v(" "+_vm._s(_vm.title)+" "+_vm._s((" (Опубликованных: " + _vm.publishedPostsLength + ", всего: " + (_vm.items && _vm.items.length > 0 ? _vm.items.length : 0) + ")"))+" ")]),(!_vm.disableAdding)?_c('v-divider',{staticClass:"ma-2",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disableAdding)?_c('v-btn',_vm._g({staticClass:"mt-3 mx-2",attrs:{"color":"primary","to":_vm.addRoute ? _vm.addRoute : { path: ("/" + _vm.resource + "/0") },"dark":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Добавить")])])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-1 table-striped",attrs:{"items":_vm.items,"headers":_vm.visibleColumns,"loading":_vm.loading,"items-per-page":_vm.rowsCount,"hide-default-footer":"","hide-default-header":"","dense":_vm.compactMode ? true : false,"no-data-text":"Нет данных"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.visibleColumns),function(header){return _c('td',{key:header.name},[(_vm.isDate(header.name) || header.type === 'date')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[_vm._v(" "+_vm._s(item[header.name] ? _vm.moment(item[header.name]).format("DD.MM.YY HH:mm") : "")+" ")]):(
              header.maxLength &&
                item[header.name] &&
                item[header.name].length >= header.maxLength
            )?_c('TextCell',{attrs:{"text":item[header.name],"max-length":header.maxLength}}):(header.type === 'object' && item[header.name])?_c('div',{staticClass:"cell-dense-height cell-align-center"},[(_vm.showTooltip(item[header.name].title, header.maxLength))?_c('TextCell',{attrs:{"text":item[header.name].title,"max-length":header.maxLength}}):_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name].title && item[header.name].title.length ? item[header.name].title : "")+" ")])],1):(header.type === 'action')?_c('div',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.$router
                          .push(
                            _vm.rowTo
                              ? {
                                name: _vm.rowTo,
                                params: { id: item.id },
                              }
                              : { path: ("/admin-panel/" + _vm.path + "/" + (item.id)) }
                          )
                          .catch(function (err) {})}}},on),[_c('v-icon',[_vm._v(" mdi-pencil-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать")])])],1),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"primary","disabled":item.isPublished === 'Да'},on:{"click":function($event){return _vm.publishPost(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-publish ")])],1)]}}],null,true)},[_c('span',[_vm._v("Опубликовать")])])],1),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.removePost(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])],1)],1)],1):_c('div',{staticClass:"cell-dense-height cell-align-center"},[_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name])+" ")])])],1)}),0)]}}])},[_c('template',{slot:"header"},[_c('thead',[_c('tr',_vm._l((_vm.visibleColumns),function(header){return _c('th',{key:header.name,staticClass:"pl-3 pr-0 py-1",style:({
              minWidth: header.width ? ((header.width) + "px") : 'auto',
              whiteSpace: header.width ? 'normal' : 'nowrap',
            })},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])])],2),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10,"color":"primary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }