<template>
  <div>
    <v-card
      tile
      class="elevation-1 mb-1"
    >
      <v-card-title class="pa-2">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on}">
              <v-btn
                slot="activator"
                small
                icon
                class="my-3 mx-2"
                @click="$router.go(-1)"
                v-on="on"
              >
                <v-icon color="grey darken-1">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Назад</span>
          </v-tooltip>
          <h1 class="title my-3 mx-2">
            {{ title }} {{ ` (Опубликованных: ${publishedPostsLength}, всего: ${items && items.length > 0 ? items.length : 0})` }}
          </h1>
          <v-divider
            v-if="!disableAdding"
            class="ma-2"
            inset
            vertical
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="!disableAdding"
                color="primary"
                :to="addRoute ? addRoute : { path: `/${resource}/0` }"
                dark
                small
                class="mt-3 mx-2"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Добавить</span>
          </v-tooltip>
        </v-row>
      </v-card-title>
    </v-card>
    <v-data-table
      :items="items"
      :headers="visibleColumns"
      :loading="loading"
      :items-per-page="rowsCount"
      hide-default-footer
      hide-default-header
      :dense="compactMode ? true : false"
      class="elevation-1 mb-1 table-striped"
      no-data-text="Нет данных"
    >
      <template slot="header">
        <thead>
          <tr>
            <th
              v-for="header in visibleColumns"
              :key="header.name"
              class="pl-3 pr-0 py-1"
              :style="{
                minWidth: header.width ? `${header.width}px` : 'auto',
                whiteSpace: header.width ? 'normal' : 'nowrap',
              }"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="header in visibleColumns"
            :key="header.name"
          >
            <div
              v-if="isDate(header.name) || header.type === 'date'"
              class="cell-dense-height cell-align-center"
            >
              {{
                item[header.name]
                  ? moment(item[header.name]).format("DD.MM.YY HH:mm")
                  : ""
              }}
            </div>
            <TextCell
              v-else-if="
                header.maxLength &&
                  item[header.name] &&
                  item[header.name].length >= header.maxLength
              "
              :text="item[header.name]"
              :max-length="header.maxLength"
            />
            <div
              v-else-if="header.type === 'object' && item[header.name]"
              class="cell-dense-height cell-align-center"
            >
              <TextCell
                v-if="showTooltip(item[header.name].title, header.maxLength)"
                :text="item[header.name].title"
                :max-length="header.maxLength"
              />
              <span
                v-else
                :style="getCropTextCell(header)"
              >
                {{
                  item[header.name].title && item[header.name].title.length
                    ? item[header.name].title
                    : ""
                }}
              </span>
            </div>
            <div v-else-if="header.type === 'action'">
              <v-layout row>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        @click="
                          $router
                            .push(
                              rowTo
                                ? {
                                  name: rowTo,
                                  params: { id: item.id },
                                }
                                : { path: `/admin-panel/${path}/${item.id}` }
                            )
                            .catch((err) => {})
                        "
                        v-on="on"
                      >
                        <v-icon> mdi-pencil-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        :disabled="item.isPublished === 'Да'"
                        @click="publishPost(item.id)"
                        v-on="on"
                      >
                        <v-icon> mdi-publish </v-icon>
                      </v-btn>
                    </template>
                    <span>Опубликовать</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        @click="removePost(item.id)"
                        v-on="on"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </div>
            <div
              v-else
              class="cell-dense-height cell-align-center"
            >
              <span :style="getCropTextCell(header)">
                {{ item[header.name] }}
              </span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        :total-visible="10"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import * as postsApi from "../../../../backend/postsApi";
import { saveDataListState } from "../../../../helpers/dataListState";
import { createUrl } from "../../../../helpers/urlQueryHelper.js";
import TextCell from "../../../../components/cells/CropTextCell.vue";

export default {
  components: {
    TextCell,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disableAdding: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    addRoute: {
      type: Object,
      default: null,
      required: false,
    },
    resource: {
      type: String,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    store: {
      type: String,
      default: null,
    },
    rowTo: {
      type: String,
      required: false,
      default: null,
    },
    path: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalItems: null,
      compactMode: false,
    };
  },
  computed: {
    publishedPostsLength(){
      const arr = this.items.filter(i => i.isPublished === "Да");
      return arr && arr.length > 0 ? arr.length : 0;
    },
    pages() {
      if (
        !this.pagination ||
        this.pagination.rowsPerPage === null ||
        this.totalItems === null
      ) {
        return 0;
      }

      return Math.ceil(this.totalItems / this.pagination.rowsPerPage);
    },
    items() {
      return this.$store.getters[`${this.store}/items`];
    },
    pagination: {
      get() {
        return this.$store.getters[`${this.store}/pagination`];
      },
      set(pagination) {
        this.$store.commit(`${this.store}/setPagination`, pagination);
      },
    },
    rowsCount() {
      if (this.compactMode) {
        return Math.floor((window.innerHeight - 310) / 35);
      } else {
        return Math.floor((window.innerHeight - 350) / 45);
      }
    },
    saveDataListState() {
      return saveDataListState;
    },
    visibleColumns() {
      const visibleColumns = this.columns.filter(
        (c) => !Object.prototype.hasOwnProperty.call(c, "visible") || c.visible,
      );
      visibleColumns.sort((a, b) => {
        let aOrder = 0;
        if (Object.prototype.hasOwnProperty.call(a, "order")) {
          aOrder = a.order;
        } else {
          aOrder = this.columns.indexOf(a);
        }
        let bOrder = 0;
        if (Object.prototype.hasOwnProperty.call(b, "order")) {
          bOrder = b.order;
        } else {
          bOrder = this.columns.indexOf(b);
        }
        if (aOrder < bOrder) {
          return -1;
        }
        if (aOrder > bOrder) {
          return 1;
        }
        return 0;
      });
      return visibleColumns;
    },
    loading: {
      get() {
        return this.$store.getters[`${this.store}/loading`];
      },
      set(loading) {
        this.$store.commit(`${this.store}/setLoading`, loading);
      },
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.$store.getters[`${this.store}/allActionsRegistered`]) {
          this.getItems();
          this.$store.commit(`${this.store}/registerAction`, "pagging");
          if (
            this.pagination &&
            this.$router.currentRoute.query.page != this.pagination.page
          ) {
            this.saveDataListState(
              this.pagination.page,
              this.pagination.rowsPerPage,
            );
          }
        }
      },
      deep: true,
    },
    rowsCount: {
      handler() {
        this.$store.commit(`${this.store}/setPagination`, {
          page: this.pagination.page,
          rowsPerPage: this.rowsCount,
          totalItems: this.pagination.totalItems,
        });
        this.$store.commit(`${this.store}/registerAction`, "rowsCount");
      },
      deep: true,
      immediate: true,
    },
    storeColumns: {
      handler() {
        this.saveDataListState(
          this.pagination.page,
          this.pagination.rowsPerPage,
        );
        this.pagination = {
          page: this.pagination.page,
          rowsPerPage: this.rowsCount,
          totalItems: this.pagination.totalItems,
        };
        this.$store.commit(`${this.store}/registerAction`, "settings");
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.getItem("page")) {
      try {
        const pagination = {
          page: JSON.parse(localStorage.getItem("page")),
          rowsPerPage: this.rowsCount,
          totalItems: this.pagination.totalItems,
        };
        this.$store.commit(`${this.store}/setPagination`, pagination);
      } catch (e) {
        localStorage.removeItem("pagination");
      }
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    isDate(headerName) {
      var reg = /date+$/i;
      return reg.test(headerName);
    },
    async getItemsFromApi() {
      this.$store.commit(`${this.store}/setLoading`, true);
      const url = createUrl(this.pagination.page, this.pagination.rowsPerPage);

      const res = await postsApi.getItems(this.resource, url);
      const items = res.items.map((i) => {
        const item = { id: i.id };
        this.visibleColumns.forEach((c) => {
          if (c.dataPath) {
            const dataPathSegments = c.dataPath.split(".");
            let value = i;
            dataPathSegments.forEach((s) => (value ? (value = value[s]) : ""));
            item[c.name] = value;
          } else if (c.valueFn) {
            item[c.name] = c.valueFn(i);
          } else {
            if (Object.prototype.hasOwnProperty.call(i, c.name)) {
              item[c.name] = i[c.name];
            }
          }
        });
        return item;
      });

      this.$store.commit(`${this.store}/setLoading`, false);
      return { items, total: res.totalItems };
    },
    getItems() {
      return new Promise((resolve, reject) => {
        this.getItemsFromApi()
          .then((data) => {
            this.$store.commit(`${this.store}/setItems`, data.items);
            this.totalItems = data.total;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeCompactMode() {
      this.compactMode = !this.compactMode;
    },
    getCropTextCell(header) {
      return `min-width: ${header.width ? `${header.width}px` : "auto"}; 
      width: ${header.width ? `${header.width}px` : "auto"}; 
      white-space: ${header.width ? "nowrap" : "normal"}; 
      ${header.width ? "text-overflow: ellipsis" : ""};
      overflow:  ${header.width ? "hidden" : "visible"}`;
    },
    showTooltip(item, maxLength) {
      if (!item) {
        return;
      }
      if (item.length > maxLength) {
        return true;
      }
      if (item.length < maxLength) {
        return false;
      }
    },
    getArrayText(item) {
      if (item) {
        return item.join("; ");
      }
    },
    async publishPost(id) {
      this.loading = true;
      await postsApi.publishPost(id);
      this.loading = false;
      this.getItems();
    },
    async removePost(id) {
      this.loading = true;
      await postsApi.removePost(id);
      this.loading = false;
      this.getItems();
    },
  },
};
</script>

<style scoped>
.cell-fixed-height {
  max-height: 50px;
  height: 50px;
  overflow: hidden;
}
.cell-align-center {
  display: flex;
  align-items: center;
}
.cell-dense-height {
  max-height: 35px;
  height: 35px;
  overflow: hidden;
}
</style>
